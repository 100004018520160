#checkmark {
  display:inline-block;
  width: 48px;
  height:48px;
  background: white;
  border: solid 2px grey;
  border-radius:50%;
  /*-ms-transform: rotate(45deg); /* IE 9 */
  /*-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

#checkmark:before{
  content:"";
  position: absolute;
  width:5px;
  height:13px;
  background-color: green;  
  left:22px;
  top:15px;
}

#checkmark:after{
  content:"";
  position: absolute;
  width:5px;
  height:5px;
  background-color: green;  
  left:18px;
  top:23px;
}

#checkmark.disabled:before{
  background-color: grey
}
#checkmark.disabled:after{
  background-color: grey
}

.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}